import React from 'react'

export default function AddGuests(props) {
    const [values, setValues] = React.useState({
        first_name: '',
        last_name: '',
        phone_number: '',
    });

    const handleChange = (property) => (event) => {
        let value = event.target.value;
        let id = event.target.id;
        singleErrorCheck(id, value);
        if(id === 'phone_number') {
            if(!isNumber(value)) {return}
        }
        // props.handleDataChange('phone', values);
        setValues({ ...values, [property]: value });
    };

    const isNumber = (value) => {
        let validNumbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        for(let i = 0; i < value.length; i++) {
            if(!validNumbers.includes(value[i])) {
                return false;
            }
        }
        return true;
    }

    let errorStyle = (id, property, style) => {document.getElementById(`${id}`).style[property] = style}

    let errorTarget = (id, value) => {
        let errors = false;
        // let errorStyle = (style) => {document.getElementById(`${id}-container`).style.borderBottom = style}
        if(value.length < 3){
            errorStyle(`${id}-container`, 'borderBottom', '3px solid #ef4b4b')
            errors = true;
        } else {
            errorStyle(`${id}-container`, 'borderBottom', '')
            // errorStyle('');
        }
        return errors;
    }

    const singleErrorCheck = (target, value) => {
        errorTarget(target, value);
    }

    let infoProps = ['first_name', 'last_name', 'phone_number'];
    let hasErrors = () => {
        let errors = [];
        let keys = infoProps;
        for(let i = 0; i < keys.length; i++) {
            let id = keys[i];
            let value = values[id];
            errors.push(errorTarget(id, value));
        }
        return errors.includes(true) ? true : false;
    }

    let addGuest = () => {
        if(hasErrors()) {return null;}
        props.passGuestDataUp({
            guestFirstName: values.first_name,
            guestLastName: values.last_name,
            guestPhoneNumber: values.phone_number
        });
    }

    return (
        <div className="addGuests">
            <form className="col s12">
                <div className="row">
                  <div className="input-field col s6" id="first_name-container">
                    <input id="first_name" type="text" className="validate" onChange={handleChange('first_name')} value={values.first_name}/>
                    <label htmlFor="first_name">First Name<sup>*</sup></label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6" id="last_name-container">
                    <input id="last_name" type="text" className="validate" onChange={handleChange('last_name')} value={values.last_name}/>
                    <label htmlFor="last_name">Last Name<sup>*</sup></label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s6" id="phone_number-container">
                    <input id="phone_number" type="text" className="validate" onChange={handleChange('phone_number')} value={values.phone_number}/>
                    <label htmlFor="phone_number">Phone Number<sup>*</sup></label>
                  </div>
                </div>
            </form>
            <button className="submitGuest" onClick={addGuest}>Include Guest</button>
        </div>
    )
}
