import React from 'react'

export default function LoadingScreen(props) {
    let loaders = {
        animatedSquares: 
            <>
                <div className="availableDates fake">
                    <div className="dateContainer left">
                        <div className="dates">
                            <h3 className="date">
                                13
                                <div className="stripe"></div>
                            </h3>
                            <h3 className="day">
                                wed
                                <div className="stripe"></div>
                            </h3>
                            <span></span>
                        </div>
                    </div>

                    <div className="events">
                        <div className="event1 event">
                            <div className="eventInfoContainer">
                                <div className="stripe"></div>
                                <h4 className="time">
                                    9:00AM
                                    <div className="stripe"></div>
                                </h4>
                            </div>
                        </div>

                        <div className="event1 event">
                            <div className="eventInfoContainer">
                                <div className="stripe"></div>
                                <h4 className="time">
                                    9:00AM
                                    <div className="stripe"></div>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="availableDates fake">
                <div className="dateContainer left">
                    <div className="dates">
                        <h3 className="date">
                            13
                            <div className="stripe"></div>
                        </h3>
                        <h3 className="day">
                            wed
                            <div className="stripe"></div>
                        </h3>
                        <span></span>
                    </div>
                </div>

                <div className="events">
                    <div className="event1 event">
                        <div className="eventInfoContainer">
                            <div className="stripe"></div>
                            <h4 className="time">
                                9:00AM
                                <div className="stripe"></div>
                            </h4>
                        </div>
                    </div>

                    <div className="event1 event">
                        <div className="eventInfoContainer">
                            <div className="stripe"></div>
                            <h4 className="time">
                                9:00AM
                                <div className="stripe"></div>
                            </h4>
                        </div>
                    </div>

                    <div className="event1 event">
                        <div className="eventInfoContainer">
                            <div className="stripe"></div>
                            <h4 className="time">
                                9:00AM
                                <div className="stripe"></div>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            </>,
        spinner: 
            <div className="loadingSpinner">
                <div className="loadingContent">
                    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
    }
    return (
        loaders[props.type]
    )
}
