import React from 'react'

export default function RsvpList(props) {
    let rsvpDates = props.rsvpDates ? props.rsvpDates : [];
    return (
        <div className={`rsvpList ${props.open ? '' : 'hidden-down'}`}>
            <div className="rsvpDates">
                <h3>RSVP Dates</h3>
                <div className="close" onClick={props.closeRsvpList}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </div>
                <ul>
                    {
                        rsvpDates.map((item, index) => {
                            return (
                                <li className="rsvpMonth" key={index}>
                                    <h4>{item[0]}</h4>
                                    <ul>
                                        {
                                            item.map((val, index) => {
                                                return (
                                                    index > 0 ? <li key={index}>
                                                        <h4>{val}</h4>
                                                        <span></span>
                                                    </li> : null
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
