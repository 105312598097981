import React, { Component } from 'react'
import rsvpUser from '../../store/GlobalFunctions'
import UserChildren from './UserChildren'
import UserGuests from './UserGuests'
import AddGuests from './AddGuests'

export default class ConfirmRsvp extends Component {
    state = {
        spouseSelected: false,
        children: [],
        guests: [],
        usersToRsvp: [],
        guestsToRsvp: [],
        allGuests: [],
        addGuest: false,
        totalGuestsAdded: 0,
        closeUserGuests: false
    }

    confirmRsvpDate = () => {
        // this.props.toggleReadyToRsvp()
        this.props.rsvpUser({
            usersToRsvp: this.state.usersToRsvp,
            guestsToRsvp: this.state.guestsToRsvp
        })
    }

    toggleUserGuests = () => {
        this.setState({
            closeUserGuests: !this.state.closeUserGuests
        })
    }

    addFamily = (id) => {
        let users = this.state.usersToRsvp;
        if(users.includes(id)) {
            let index = users.indexOf(id);
            users.splice(index, 1);
        } else {
            users.push(id);
        }
        this.setState({
            usersToRsvp: users
        })
    }

    includeGuest = (id) => {
        let users = this.state.usersToRsvp;
        let totalGuests = this.state.totalGuestsAdded;
        if(users.includes(id)) {
            let index = users.indexOf(id);
            totalGuests = totalGuests > 1 ? totalGuests - 1 : 0;
            users.splice(index, 1);
        } else {
            if(totalGuests < Number(this.props.rsvpIsPossible.numberOfGuestsRsvp)) {
                users.push(id);
                totalGuests += 1;
            }
        }
        this.setState({
            usersToRsvp: users,
            totalGuestsAdded: totalGuests
        })
    }

    toggleIncludeSpouse = (e) => {
        this.setState({
            spouseSelected: !this.state.spouseSelected
        });
        this.addFamily(e.target.id);
    }

    toggleAddGuest = () => {
        if (this.state.totalGuestsAdded >= Number(this.props.rsvpIsPossible.numberOfGuestsRsvp)) {return}
        this.setState({
            addGuest:true
        })
    }

    addGuests = (data) => {
        let guests = this.state.guestsToRsvp;
        if (this.state.totalGuestsAdded >= Number(this.props.rsvpIsPossible.numberOfGuestsRsvp)) {return}
        guests.push(data);
        if(guests.length < Number(this.props.rsvpIsPossible.numberOfGuestsRsvp)) {
            this.props.passToDatePicker('guestsToRsvp', guests)
            this.setState({
                guestsToRsvp: guests,
                addGuest: false,
                totalGuestsAdded: this.state.totalGuestsAdded + 1
            })
        }
    }

    removeGuest = (e) => {
        let guests = this.state.guestsToRsvp;
        guests.splice(Number(e.target.id), 1);
        this.props.passToDatePicker('guestsToRsvp', guests)
        this.setState({
            guestsToRsvp: guests,
            totalGuestsAdded: this.state.totalGuestsAdded - 1
        })
    }

    componentDidMount() {
        this.setState({
            allGuests: this.props.allGuests,
            guestsToRsvp: this.props.guestsToRsvp
        })
    }

    render() {
        console.log(this.props.kidsRsvpList);
        return (
            <div className="confirmRsvpContainer">
                <div className={`confirmRsvp ${this.props.open ? '' : 'hidden-down'}`}>
                    <div className="confirmInfo">
                        <div className="close" onClick={this.props.closeReadyToRsvp}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </div>

                        <div className="rsvpAddition">
                            <header>
                                <h3>Add Family Members</h3> 
                                <h4>{this.props.data.day}, {this.props.dateInfo.month} {this.props.dateInfo.date}</h4>
                            </header>

                            <div className="familyMembers">
                                <ul>
                                    <li>
                                        <h4>{this.props.userName.firstName} {this.props.userName.lastName}</h4>
                                        <span className="addMember" style={{backgroundColor:'gray', border: '1px solid gray', cursor: 'initial'}}>
                                            <i className='fas fa-check'></i>
                                        </span>
                                    </li>
                                {
                                    (this.props.userSpouse.spouseFirstName && this.props.rsvpIsPossible.allowSpouse) ?  <li>
                                            <h4>{this.props.userSpouse.spouseFirstName} {this.props.userSpouse.spouseLastName}</h4>
                                            <span className="addMember spouse" id={this.props.userSpouse.spouseUserId} onClick={this.toggleIncludeSpouse} style={this.state.spouseSelected ? {backgroundColor: 'green', border: '1px solid green'} : {}}>
                                                {this.state.spouseSelected ? <i className='fas fa-check'></i> : null}
                                            </span>
                                        </li> : null
                                }
                                
                                {
                                    (this.props.userChildren.length > 0 && this.props.rsvpIsPossible.allowChildren) ? 
                                    this.props.userChildren.map((item, index) => {
                                        let userHasRsvped = this.props.kidsRsvpList.some(prop => prop.childUserId === item.childUserId);
                                        return (
                                            !userHasRsvped ? 
                                            <UserChildren
                                                index={index}
                                                item={item}
                                                key={index}
                                                addFamily={this.addFamily}
                                            /> : null
                                        )
                                    }) : null
                                }
                                
                                {
                                    this.props.rsvpIsPossible.allowGuests && this.state.allGuests.length > 0 ? 
                                    <>
                                    <h4 className="guestsHeaders" onClick={this.toggleUserGuests}>Guests <span><i className={`las la-angle-${this.state.closeUserGuests ? 'down' : 'up'}`}></i></span></h4>
                                    {this.state.allGuests.map((item, index) => {
                                        return (
                                            <UserGuests
                                                index={index}
                                                item={item}
                                                key={index}
                                                totalGuestsAdded={this.state.totalGuestsAdded}
                                                maxGuests={Number(this.props.rsvpIsPossible.numberOfGuestsRsvp)}
                                                includeGuest={this.includeGuest}
                                                close={this.state.closeUserGuests}
                                            />
                                        )
                                    })} </>: null
                                }
                                
                                {
                                    this.props.rsvpIsPossible.allowGuests && this.state.guestsToRsvp.length > 0 ? 
                                    <>
                                    <h4 className="guestsHeaders">New Guests</h4>
                                    {this.state.guestsToRsvp.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <li key={index}>
                                                    <h4>{item.guestFirstName} {item.guestLastName}: {item.guestPhoneNumber}</h4>
                                                    <span className="removeMember">
                                                        <i className="fas fa-trash-alt" id={index} onClick={this.removeGuest}></i>
                                                    </span>
                                                </li>
                                            </React.Fragment> 
                                        )
                                    })} </> : null
                                }
                                </ul>
                            </div>

                            <div className="guests">
                                {this.state.totalGuestsAdded < Number(this.props.rsvpIsPossible.numberOfGuestsRsvp) && this.props.rsvpIsPossible.allowGuests ?
                                    this.state.addGuest ? <AddGuests
                                        passGuestDataUp={this.addGuests}
                                    /> : <button className="addGuestsBtn" onClick={this.toggleAddGuest}><h4>Add Guests</h4> </button> : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="options">
                        <div className="btn yes" onClick={this.confirmRsvpDate}> <h4>Reserve</h4> </div>
                        <div className="btn cancel" onClick={this.props.closeReadyToRsvp}> <h4>Cancel</h4> </div>
                    </div>
                </div>
            </div>
        )
    }
}
