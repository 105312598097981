import React, { Component } from 'react'

export default class Message extends Component {
    state = {
        showMessage: false
    }

    showMessage(length) {
        this.setState({
            showMessage: true
        })
        window.setTimeout(() => {
            this.setState({
                showMessage: false
            })
        }, 1000 * length)
    }

    componentDidMount() {
        let confirmed = this.props.dateConfirmationComplete;
        // console.log(confirmed && !this.state.showMessage);
        if(confirmed) {
            this.showMessage(3);
        }
    }

    render() {
        return (
            <div className={`complete-message${this.state.showMessage ? '' : '-hidden'}`}>
                <div className="message">
                    <h4>Reservation completed!</h4>
                    <span className="checkMark">
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </span>
                </div>
            </div>
        )
    }
}
