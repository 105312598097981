import React from 'react'

export default function Month(props) {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (
        <header className="month">
            <h3>{months[props.monthIndex]} {props.year}</h3>
        </header>
    )
}
