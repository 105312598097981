import React from 'react'

export default function UserGuests(props) {
    const [selected, setSelected] = React.useState(false);
    let toggleIncludeChild = (e) => {
        if(props.totalGuestsAdded >= props.maxGuests && !selected) {return}
        setSelected(!selected);
        props.includeGuest(props.item.guestUserId);
    }
    return (
        <div className={props.close ? 'display_none' : ''}>
            <li key={props.index}>
                <h4>{props.item.guestFirstName} {props.item.guestLastName}</h4>
                <span className="addMember guests" id={props.item.guestId} onClick={toggleIncludeChild} style={selected ? {backgroundColor: 'green', border: '1px solid green'} : {}}>
                    {selected ? <i className='fas fa-check'></i> : null}
                </span>
            </li>
        </div>
    )
}
