import React, { Component } from 'react'
import ConfirmRsvp from './confirmrsvp/ConfirmRsvp';
import { act } from 'react-dom/test-utils';
import axios from 'axios';

export default class Event extends Component {
    state = {
        isOpen: false,
        readyToRsvp: false,
        action: '',
        userId: '0',
        orgId: '0',
        username: 'cpGridKonnect12',
        password: 'L1feStyle2WEalth#5161',
        guestsToRsvp: [],
        usersToRsvp: [],
        allGuests: [],
        error: false
    }

    toggleReadyToRsvp = () => {
        this.setState({
            readyToRsvp: !this.state.readyToRsvp,
            clicked: this.state.readyToRsvp && this.state.isOpen ? false : true,
            error: false

        })
    }

    closeReadyToRsvp = () => {
        this.setState({
            readyToRsvp: false,
            clicked: this.state.readyToRsvp && this.state.isOpen ? false : true,
            error: false
        })
    }

    passUpProps = (key, data) => {
        this.setState({
            [key]: data
        })
    }

    rsvpUser = (guestData) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`);
        let serviceRecord = this.state.serviceNDate.split('_')[0];
        let date = this.state.serviceNDate.split('_')[1];
        console.log('hello');

        let data = JSON.stringify({
            "userId": this.state.userId,
            "orgId": this.state.orgId,
            "datesToRsvpList": [
                {
                    "rsvpServiceRecordId": serviceRecord,
                    "rsvpEventDate": date
                }
            ],
            "listOfUserIdsToRsvp": [this.state.userId, ...guestData.usersToRsvp],
            "listOfGuestsInformationToRsvp": guestData.guestsToRsvp
        });
        // console.log(data);
        axios.post('https://api.churchpad.com/v3/setrsvp2/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            let errorReserving = res.data.responseMessage === 'success' ? false : true;
            if(errorReserving) {
                console.log('There was an error');
                this.displayError(`There was an error: ${res.data.responseMessage}`);
            } else {
                console.log('User has been RSVPed');
                this.props.setUserRsvpData(this.state.userId, this.state.orgId)
                this.setState({
                    clicked: false,
                    error: false,
                    readyToRsvp: false
                });
            }
            // console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }

    displayError = (error) => {
        this.setState({
            error: error
        })
    }

    unRsvpUser = (unRsvpList) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        let serviceRecord = this.state.serviceNDate.split('_')[0];
        let date = this.state.serviceNDate.split('_')[1];

        let data = JSON.stringify({
            "userId": this.state.userId,
            "orgId": this.state.orgId,
            "rsvpServiceRecordId": serviceRecord,
            "rsvpEventDate": date,
            "listOfUserIdsToUnRsvp": [this.state.userId, ...unRsvpList]
        });
        // console.log(data);
        axios.post('https://api.churchpad.com/v3/unrsvp/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
             },
        }).then(res => {
            console.log('User has been UnRSVPed');
            // console.log(res)
            this.props.setUserRsvpData(this.state.userId, this.state.orgId);
            this.setState({
                clicked: false
            })
        }).catch(err => {
            console.log(err);
        })
    }

    unRsvpAll = (e) => {
        let unRsvpList = [];
        let guests = this.props.guestRsvpList;
        let spouse = this.props.spouseRsvpList;
        let kids = this.props.kidsRsvpList;
        let setArr = (arr, type) => {
            for (let i = 0; i < arr.length; i++) {
                unRsvpList.push(arr[i][`${type}UserId`]);
            }
        }
        setArr(guests, 'guest');
        setArr(spouse, 'spouse');
        setArr(kids, 'child');
        // console.log(this.props);
        this.unRsvpUser(unRsvpList);
        // console.log(unRsvpList);
    }

    toggleClicked = (e) => {
        let userHasRsvped = this.props.userHasRsvped;
        if(!this.state.isOpen) {
            this.props.passUpProps('selected', this.props.item);
        }
        this.setState({
            isOpen: !this.state.isOpen,
            readyToRsvp: false,
            action: userHasRsvped ? 'unRsvp' : 'rsvp',
            error: false
        })
    }

    trunc_text = (text, max) => {
        max = (text.length < max) ? text.length : max;

        return text.substring(0, max);
    }

    convertMilitaryTimeToLocal(time) {
        let timeArr = time.split(':');
        let hour = timeArr[0];

        if(hour === 0) {
            timeArr[0] =  '12';
        } else if(hour > 12) {
            timeArr[0] = timeArr[0] - 12;
        };
        timeArr = timeArr.join(":");
        console.log(hour);
        timeArr +=  hour >= 12 ? 'PM' : 'AM'
        return timeArr
    }

    componentDidMount() {
        let {item} = this.props;
        this.setState({
            orgId: this.props.orgId,
            userId: this.props.userId,
            serviceNDate: `${item.rsvpServiceRecordId}_${item.allDates}`,
            guestsToRsvp: this.props.guestsToRsvp,
            allGuests: [...this.props.userData.userGuestsInfo, ...this.props.guestsToRsvp]
        })
    }

    render() {
        let {index} = this.props;
        let {item} = this.props;
        let userHasRsvped = this.props.userHasRsvped;
        return (
            <>
                <div key={index} className={`event1 event ${userHasRsvped && !this.state.isOpen ? 'selectedEvent' : (this.state.isOpen ? 'notRsvpClick' : '')}`}  id={`${item.rsvpServiceRecordId}_${item.allDates}`}>
                    <div className="eventContainer">
                        <div className="eventInfoContainer" id={`${item.rsvpServiceRecordId}_${item.allDates}`} onClick={this.toggleClicked}>
                            <div className="eventInfo">
                                <h4 id={`${item.rsvpServiceRecordId}_${item.allDates}`} className="serviceName">{item.serviceName}</h4>
                                <h4 id={`${item.rsvpServiceRecordId}_${item.allDates}`} className={userHasRsvped ? "rsvpConfirmed" : 'eventDesc'}>{userHasRsvped ? 'RESERVED' : !this.state.isOpen ? "CLICK TO RESERVE" : ''}</h4>
                            </div>
                            {/* {
                                !userHasRsvped ? <h4 id={`${item.rsvpServiceRecordId}_${item.allDates}`} className={`cap ${userHasRsvped ? 'red-text' : ''}`}>{`${item.availableSpots} Spots Left`}</h4> : null
                            } */}
                            <div id={`${item.rsvpServiceRecordId}_${item.allDates}`} className="serviceDetails">
                                <h4 id={`${item.rsvpServiceRecordId}_${item.allDates}`} className="time">{this.convertMilitaryTimeToLocal(item.serviceTime)}</h4>
                            </div>
                        </div>
                        <h4 className={`desc ${this.state.isOpen ? 'descShown' : ''}`} id={`${item.rsvpServiceRecordId}_${item.allDates}`}>
                            {item.desc}
                        </h4>
                    </div>
                        {
                            this.state.readyToRsvp ?
                                <ConfirmRsvp
                                    open={true}
                                    userChildren={this.props.userData.userChildrenInfo}
                                    userSpouse={this.props.userData.userSpouseInfo}
                                    userName={{firstName: this.props.userData.userFirstName, lastName: this.props.userData.userLastName}}
                                    data={item}
                                    dateInfo={this.props.dateInfo}
                                    toggleReadyToRsvp={this.toggleReadyToRsvp}
                                    closeReadyToRsvp={this.closeReadyToRsvp}
                                    rsvpUser={this.rsvpUser}
                                    passUpProps={this.passUpProps}
                                    passToDatePicker={this.props.passUpProps}
                                    guestsToRsvp={this.state.guestsToRsvp}
                                    allGuests={this.state.allGuests}
                                    guestRsvpList= {this.props.guestRsvpList}
                                    kidsRsvpList= {this.props.kidsRsvpList}
                                    spouseRsvpList= {this.props.spouseRsvpList}
                                    rsvpIsPossible={this.props.rsvpIsPossible}
                                /> : null
                        }
                        {
                            this.state.error ? 
                            <div className="errorDisplay">
                                <span className="center"><i className="las la-exclamation"></i></span>
                                <h4>{this.state.error}</h4>
                            </div> : null
                        }
                        {
                            this.state.readyToRsvp ? null :
                            <div className={'confirmRsvpButtons'} style={this.state.isOpen ? {position: 'relative', top: '0px', opacity: 1} : {}}>
                                <div className="options">
                                    <button disabled={this.state.isOpen ? false : true} className={`btn ${userHasRsvped ? 'unRsvp' : 'rsvp'}`} onClick={userHasRsvped ? this.unRsvpAll : this.toggleReadyToRsvp} id={`${item.rsvpServiceRecordId}_${item.allDates}`}> <h4 id={`${item.rsvpServiceRecordId}_${item.allDates}`}>{this.props.rsvpButtonLabel && this.props.unRsvpButtonLabel ? (userHasRsvped ? this.props.unRsvpButtonLabel : this.props.rsvpButtonLabel) : (userHasRsvped ? 'unRSVP' : 'RSVP')} </h4> </button>
                                    <button disabled={this.state.isOpen ? false : true} className="btn no" onClick={this.toggleClicked}> <h4>Cancel</h4> </button>
                                </div>
                            </div>
                        }
                    </div>  
            </>
        )
    }
}
