import React, { Component } from 'react';
// import { BrowserRouter, Switch, Route } from 'react-router-dom'
import DatePicker from './components/screens/DatePicker';
import ShowCalender from './components/screens/ShowCalender';
import './assets/scss/main.css'

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="main_container">
              <div className="main">
                <DatePicker/>
              </div>
        </div>
      </div> 
    );
  }
}

export default App;