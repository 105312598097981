import React, { Component } from 'react'
import Header from '../layout/Header'
import ConfirmRsvp from '../layout/confirmrsvp/ConfirmRsvp';
import Event from '../layout/Event';
import LoadingScreen from '../layout/LoadingScreen';
import Message from '../layout/Message';
import axios from 'axios';

export default class DatePicker extends Component {

    state = {
        date: new Date(),
        username: 'cpGridKonnect12',
        password: 'L1feStyle2WEalth#5161',
        userData: [],
        orgData: [],
        userDataSet: false,
        orgDataSet: false,
        rsvpDataSet: false,
        userRsvpDataSet: false,
        userExistingRsvpList: [],
        userId: false,
        orgId: false,
        initLoading: true,
        failedToLoad: false,
        loadingErrorMessage: '',
        showMoreChurchInfo: false,
        userFamily: false,
        guestsToRsvp: []
    }

    showMoreChurchInfo = () => {
        this.setState({
            showMoreChurchInfo: !this.state.showMoreChurchInfo
        })
    }

    setOrgData = (userId, orgId) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        axios.get(`https://api.churchpad.com/v3/organizations/?orgId=${orgId}`, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            headers : { 'Authorization' : `Basic ${tok}` }
        }).then(res => {
            let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
            if(!data) {
                this.setState({
                    initLoading: false,
                    failedToLoad: true,
                    loadingErrorMessage: res.data.responseMessage
                })
            } else {
                this.setState({
                    orgData: data,
                    orgDataSet: true,
                    responseMessage: res.data.responseMessage,
                })
                this.setUser(userId, orgId);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    
    setUser = (userId, orgId) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        axios.get(`https://api.churchpad.com/v3/users/?userId=${userId}&orgId=${orgId}`, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            headers : { 'Authorization' : `Basic ${tok}` }
        }).then(res => {
            let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
            // console.log(res);
            if(!data) {
                this.setState({
                    initLoading: false,
                    failedToLoad: true,
                    loadingErrorMessage: res.data.responseMessage
                })
            } else {
                this.setState({
                    userData: data,
                    userDataSet: data ? true : false,
                    responseMessage: res.data.responseMessage
                });
                this.setUserRsvpData(userId, orgId);
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    passUpProps = (key, data) => {
        this.setState({
            [key]: data
        })
    }

    setUserRsvpData = (userId, orgId) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        axios.get(`https://api.churchpad.com/v3/rsvp/?userId=${userId}&orgId=${orgId}`, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            headers : { 'Authorization' : `Basic ${tok}` }
        }).then(res => {
            let data = res.data.responseMessage === 'success' ? res.data.responseData : false;
            // console.log(res);
            if(!data) {
                this.setState({
                    initLoading: false,
                    failedToLoad: true,
                    loadingErrorMessage: res.data.responseMessage
                })
            } else {
                this.setState({
                    userExistingRsvpList: data.myExistingRsvpedList,
                    guestExistingRsvpList: data.guestsExistingRsvpedList,
                    childrenExistingRsvpList: data.childrenExistingRsvpedList,
                    spouseExistingRsvpList: data.spouseExistingRsvpedList,
                    userRsvpDataSet: data ? true : false,
                    responseMessage: res.data.responseMessage
                });
                if(this.state.oldMethod) {
                    this.setAvailableRsvpDatesOld(userId, orgId);
                } else {
                    this.setAvailableRsvpDates();
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    setAvailableRsvpDatesOld = (userId, orgId) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        axios.get(`https://api.churchpad.com/v3/rsvp/?userId=${userId}&orgId=${orgId}`, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            headers : { 'Authorization' : `Basic ${tok}` }
        }).then(res => {
            let data = res.data.responseMessage === 'success' ? res.data.responseData.rsvpServiceList : false;
            if(!data) {
                this.setState({
                    initLoading: false,
                    failedToLoad: true,
                    loadingErrorMessage: res.data.responseMessage
                })
            } else {
                let list = [];
                for(let i = 0; i < data.length; i++) {

                    let item = data[i];
                    let allDates = item.rsvpEventDatesList.split(',');
                    if(allDates.length > 1) {
                        for(let j = 0; j < allDates.length; j++) {
                            list.push({
                                allDates: item.rsvpEventDatesList.split(',')[j],
                                availableSpots: item.rsvpServiceAvailableSpots,
                                day: item.rsvpServiceDay,
                                desc: item.rsvpServiceDescription,
                                serviceType: item.rsvpServiceLogic,
                                maxCap: item.rsvpServiceMaximumCapacity,
                                serviceName: item.rsvpServiceName,
                                serviceRecord: item.rsvpServiceRecordId,
                                serviceTime: item.rsvpServiceTime,
                                rsvpServiceRecordId: item.rsvpServiceRecordId,
                                dateNumber: item.rsvpEventDatesList.split(',')[j].replace(/[-]+/g, '')
                            })
                        }
                    } else {
                        list.push({
                            allDates: item.rsvpEventDatesList,
                            availableSpots: item.rsvpServiceAvailableSpots,
                            day: item.rsvpServiceDay,
                            desc: item.rsvpServiceDescription,
                            serviceType: item.rsvpServiceLogic,
                            maxCap: item.rsvpServiceMaximumCapacity,
                            serviceName: item.rsvpServiceName,
                            serviceRecord: item.rsvpServiceRecordId,
                            serviceTime: item.rsvpServiceTime,
                            rsvpServiceRecordId: item.rsvpServiceRecordId,
                            dateNumber: item.rsvpEventDatesList.replace(/[-]+/g, '')
                        })
                    }
                }
                list.sort((a, b) => (a.dateNumber > b.dateNumber) ? 1 : -1);
                this.setState({
                    availableDates: this.concatData(list),
                    rsvpDataSet: true,
                    initLoading: false,
                    responseMessage: res.data.responseMessage
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    setAvailableRsvpDates = () => {
        let data = this.state.availableDates;
        if(!data) {
            this.setState({
                initLoading: false,
                failedToLoad: true,
                // loadingErrorMessage: res.data.responseMessage
            })
        } else {
            let list = [];
            for(let i = 0; i < data.length; i++) {

                let item = data[i];
                let allDates = item.rsvpEventDatesList.split(',');
                if(allDates.length > 1) {
                    for(let j = 0; j < allDates.length; j++) {
                        list.push({
                            allDates: item.rsvpEventDatesList.split(',')[j],
                            availableSpots: item.rsvpServiceAvailableSpots,
                            day: item.rsvpServiceDay,
                            desc: item.rsvpServiceDescription,
                            serviceType: item.rsvpServiceLogic,
                            maxCap: item.rsvpServiceMaximumCapacity,
                            serviceName: item.rsvpServiceName,
                            serviceRecord: item.rsvpServiceRecordId,
                            serviceTime: item.rsvpServiceTime,
                            rsvpServiceRecordId: item.rsvpServiceRecordId,
                            dateNumber: item.rsvpEventDatesList.split(',')[j].replace(/[-]+/g, '')
                        })
                    }
                } else {
                    list.push({
                        allDates: item.rsvpEventDatesList,
                        availableSpots: item.rsvpServiceAvailableSpots,
                        day: item.rsvpServiceDay,
                        desc: item.rsvpServiceDescription,
                        serviceType: item.rsvpServiceLogic,
                        maxCap: item.rsvpServiceMaximumCapacity,
                        serviceName: item.rsvpServiceName,
                        serviceRecord: item.rsvpServiceRecordId,
                        serviceTime: item.rsvpServiceTime,
                        rsvpServiceRecordId: item.rsvpServiceRecordId,
                        dateNumber: item.rsvpEventDatesList.replace(/[-]+/g, '')
                    })
                }
            }
            list.sort((a, b) => (a.dateNumber > b.dateNumber) ? 1 : -1);
            this.setState({
                availableDates: this.concatData(list),
                rsvpDataSet: true,
                initLoading: false,
            })
        }
    }

    concatData = (data) => {
        let newData = [];
        let likeData = []; //dates that are similar
        if(data.length < 2) {
            return [data];
        }
        for(let i = 0; i < data.length; i++) {
            // console.log(data[i]);
            // console.log(likeData)
            if((i + 1 < data.length) && (data[i].allDates === data[i + 1].allDates)) {
                likeData.push(data[i]);
            } else {
                if((i === data.length - 1) && !(data[i].allDates === data[i - 1].allDates)) {
                    newData.push([data[i]]);
                } else {
                    // console.log('got too');
                    likeData.push(data[i]);
                    newData.push(likeData);
                    likeData = [];
                }
            }
        }
        for(let i = 0; i < newData.length; i++) {
            let list = newData[i];
            list.sort((a, b) => (a.serviceTime > b.serviceTime) ? 1 : -1);
        }
        return newData;
    }

    rsvpUser = (rsvpData) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`);
        let serviceRecord = rsvpData.split('_')[0];
        let date = rsvpData.split('_')[1];

        let data = JSON.stringify({
            "userId": this.state.userId,
            "orgId": this.state.orgId,
            "datesToRsvpList": [
                {
                    "rsvpServiceRecordId": serviceRecord,
                    "rsvpEventDate": date
                }
            ]
        })
        // let data = JSON.stringify({
        //     "userId": this.state.userId,
        //     "orgId": this.state.orgId,
        //     "listOfUsersIdsToRsvp": [
        //         {
        //             "rsvpServiceRecordId": serviceRecord,
        //             "rsvpEventDate": date
        //         }
        //     ]
        // })
        axios.post('https://api.churchpad.com/v3/setrsvp/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
            },
        }).then(res => {
            console.log('User has been RSVPed');
            // console.log(res);
            // index += 1;
            // if(index < max) {
            //     this.rsvpUser(rsvpData, index, max);
            // } else  {
            //     this.setState({
            //         rsvpComplete: true,
            //         confirmingDates: this.state.unRsvpComplete ? false : true,
            //         dateConfirmationComplete: this.state.unRsvpComplete ? true : false,
            //     })
            // }
        }).catch(err => {
            console.log(err);
        })
    }

    unRsvpUser = (rsvpData) => {
        let tok =  btoa(`${this.state.username}:${this.state.password}`)
        let serviceRecord = rsvpData.split('_')[0];
        let date = rsvpData.split('_')[1];

        let data = JSON.stringify({
            "userId": this.state.userId,
            "orgId": this.state.orgId, 
            "rsvpServiceRecordId": serviceRecord,
            "rsvpEventDate": date
        })
        axios.post('https://api.churchpad.com/v3/unrsvp/', data, {
            headers: { 
                'Authorization' : `Basic ${tok}`,
                'Content-Type': 'application/json',
             },
        }).then(res => {
            console.log('User has been UnRSVPed');
            // index += 1;
            // if(index < max) {
            //     this.unRsvpUser(rsvpData, index, max);
            // } else {
            //     this.setState({
            //         unRsvpComplete: true,
            //         confirmingDates: this.state.rsvpComplete ? false : true,
            //         dateConfirmationComplete: this.state.rsvpComplete ? true : false,
            //     })
            //     this.setUserRsvpData(this.state.userId, this.state.orgId);
            // }
        }).catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        let params = urlParams.toString();
        const tok =  btoa(`${this.state.username}:${this.state.password}`);
        const cpValue = params.substring(3, params.length);

        axios.get(`https://api.churchpad.com/v3/rsvp/?cp=${cpValue}`, {
            // Axios looks for the `auth` option, and, if it is set, formats a
            // basic auth header for you automatically.
            headers : { 'Authorization' : `Basic ${tok}` }
        }).then(res => {
            let data = res.data;
            if(data.responseMessage !== "success") {
                params = params.split('&');
                let userId = params[0] ? params[0].substring(7, params[0].length) : false;
                let orgId = params[1] ? params[1].substring(6, params[1].length) : false;
                // console.log(orgId)
                this.setOrgData(userId, orgId);
                this.setState({
                    oldMethod: true,
                    userId: userId,
                    orgId: orgId,
                });
                return 0;
            }
            this.setState({
                userId: data.responseData.userId,
                orgId: data.responseData.orgId,
                availableDates: data.responseData.rsvpServiceList
            });
            this.setOrgData(data.responseData.userId, data.responseData.orgId);
        }).catch((err) => {
            console.log(err);
        })
        window.setTimeout(() => {
            this.setState({
                initLoading: false,
            })
        }, 3000)
    }

    render() {
        let rsvpData = this.state.availableDates ? this.state.availableDates : [];
        let userData = this.state.userData ? this.state.userData : false
        let orgData = this.state.orgData ? this.state.orgData : false
        console.log(rsvpData);
        const MONTHS = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return (
            <>
                <div className="rsvpListContainer">
                    <div className={`screen availableDatesScreen ${this.state.rsvpListOpen || this.state.confirmingDates ? ' blur' : ''}`}>
                        <Header 
                            firstName = {userData.userFirstName}
                            lastName = {userData.userLastName}
                            salutation={userData.userSalutation}
                            logo = {orgData.orgLogo}
                            profilePicture={userData.userProfilePicSmall}
                            orgShortName={orgData.orgShortName}
                            orgFullName={orgData.orgFullName}
                        />
                        <div className="title">
                            <h3>RSVP System in-person service</h3>
                        </div>
                        <div className="availableDatesContainer">
                            {
                                this.state.initLoading ? 
                                <>
                                    <LoadingScreen 
                                        type={'animatedSquares'}
                                    />
                                </>
                                : this.state.failedToLoad ? <div className="center">{this.state.loadingErrorMessage}</div> : rsvpData.map((items, index) => {
                                    let month = MONTHS[Number(items[0].allDates.split('-')[1])].substring(0, 3);
                                    let date = items[0].allDates.slice(-2);
                                    return (
                                        <div className="availableDates" key={index}>
                                            <div className="dateContainer">
                                                <div className="dates">
                                                    {/* <h4 className="day">-{items[0].day.substring(0, 3)}-</h4> */}
                                                    <h3 className="date">{month} {date}</h3>
                                                    {/* <h4 className="day"></h4> */}
                                                    <span></span>
                                                </div>
                                            </div>

                                            <div className="events">
                                                {
                                                    items.map((item, index) => {
                                                        let userHasRsvped = this.state.userExistingRsvpList.some(prop => prop.rsvpServiceRecordId === item.rsvpServiceRecordId && prop.rsvpWeekDate === item.allDates);
                                                        if(userHasRsvped) {
                                                            {/* console.log(this.state.userExistingRsvpList); */}
                                                        }
                                                        return (
                                                            <Event
                                                                item={item}
                                                                userData={userData}
                                                                index={index}
                                                                userHasRsvped={userHasRsvped}
                                                                key={index}
                                                                rsvpUser={this.rsvpUser}
                                                                unRsvpUser={this.unRsvpUser}
                                                                setUserRsvpData={this.setUserRsvpData}
                                                                userId={this.state.userId}
                                                                orgId={this.state.orgId}
                                                                rsvpButtonLabel={orgData.rsvpButtonLabel !== '' ? orgData.rsvpButtonLabel : 'RSVP'}
                                                                unRsvpButtonLabel={orgData.unRsvpButtonLabel !== '' ? orgData.unRsvpButtonLabel : 'UnRSVP'}
                                                                passUpProps={this.passUpProps}
                                                                dateInfo={{month:month, date:date}}
                                                                guestsToRsvp={this.state.guestsToRsvp}
                                                                guestRsvpList= {this.state.guestExistingRsvpList}
                                                                kidsRsvpList= {this.state.childrenExistingRsvpList}
                                                                spouseRsvpList={this.state.spouseExistingRsvpList}
                                                                rsvpIsPossible={{
                                                                    allowChildren: orgData.allowChildrenRsvp === '1' ? true : false,
                                                                    allowGuests: orgData.allowGuestsRsvp === '1' ? true : false,
                                                                    allowSpouse: orgData.allowSpouseRsvp === '1' ? true : false,
                                                                    numberOfGuestsRsvp: orgData.numberOfGuestsRsvp
                                                                }}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="bottomBox" style={this.state.showMoreChurchInfo ? {bottom:'0'} : {}} onClick={() => this.showMoreChurchInfo()}>
                        <div className="churchName">
                            <h4 className="">{orgData.orgFullName} <i className={`fas fa-caret-${this.state.showMoreChurchInfo ? 'up' : 'down'}`}></i></h4>
                        </div>
                        {this.state.showMoreChurchInfo ? <h4 className="more-info">{orgData.rsvpPageFooterInfo}</h4> : ''}
                    </div>
                </div>
            </>
        )
    }
}

    // confirmDates = () => {
    //     this.setState({
    //         confirmingDates: true
    //     });
    //     if(this.state.rsvpList.length > 0) {
    //         this.rsvpUser(this.state.rsvpList, 0, this.state.rsvpList.length);
    //     } else {
    //         this.setState({
    //             rsvpComplete: true,
    //         })
    //     }
    //     if(this.state.unRsvpList.length > 0) {
    //         this.unRsvpUser(this.state.unRsvpList, 0, this.state.unRsvpList.length);
    //     } else {
    //         this.setState({
    //             unRsvpComplete: true,
    //         })
    //     }
    // }

    // recurse = (index, max) => {
    //     // this.setState({
    //     //     recurseIndex: this.state.recurseIndex + 1
    //     // })
    //     index += 1;
    //     if(index < max) {
    //         this.recurse(index, max);
    //     }
    // }

    // pickUnRsvpDate = (e) => {
    //     let unRsvpList = this.state.unRsvpList;
    //     if(unRsvpList.includes(e.target.id)) {
    //         unRsvpList.splice(unRsvpList.indexOf(e.target.id), 1);
    //     } else {
    //         unRsvpList.push(e.target.id);
    //     }
    //     this.setState({
    //         unRsvpList,
    //         totalRsvpToRemove: this.state.totalRsvpToRemove + 1
    //     });
    // }

    // pickRsvpDate = (e) => {
    //     let rsvpList = this.state.rsvpList;
    //     let rsvpsToAdd = this.state.rsvpsToAdd;
    //     if(rsvpList.includes(e.target.id)) {
    //         rsvpList.splice(rsvpList.indexOf(e.target.id), 1);
    //         rsvpsToAdd--;
    //     } else {
    //         if(this.state.rsvpsToAdd >= this.state.maxRsvp) {return 0}
    //         rsvpList.push(e.target.id);
    //         rsvpsToAdd++;
    //     }
    //     this.setState({
    //         rsvpList,
    //         rsvpsToAdd,
    //     });
    // }