import React, { Component } from 'react'
import Month from './Month';

export default class Calender extends Component {
    state = {
        firstDay: '',
        date: new Date(),
        calender: [[]],
        dates: [`${this.props.month} ${this.props.year}`],
        maxRsvp: 3,
        rsvp: 0
    }

    pickDates = (e) => {
        let dates = this.state.dates;
        let rsvp = this.state.rsvp
        if(dates.includes(e.target.id)) {
            dates.splice(dates.indexOf(e.target.id), 1);
            rsvp--;
        } else {
            if(this.state.rsvp >= this.state.maxRsvp) {return 0}
            dates.push(e.target.id);
            rsvp++;
        }
        this.props.getRsvpData(this.props.index, dates);
        this.setState({
            dates,
            rsvp
        })
    }

    getEndDate = () => {
        let month = this.props.monthIndex;
        let year = this.props.year;
        let endDay = 30;
        if (month === 1) {
            endDay = (year % 4 === 0) ? 29 : 28;
            return endDay;
        } else if (month === 3 || month === 5 || month === 8 || month === 10) {
            endDay = 30;
            return endDay;
        } else {
            endDay = 31;
            return endDay;
        }
    }

    componentDidMount() {
        let firstDay = this.props.firstDay;

        let calender = [];
        let date = []
        for(let i = 1; i <= this.getEndDate() + firstDay; i++) {
            date.push(i < firstDay ? 0 : i - firstDay); //push current date into the date array
            if(i % 7 === 0) {
                calender.push([]) //add a new array to the calender array
                calender[calender.length - 1].push(...date); //push date into calender array.
                date = [];
            } else if(i === this.getEndDate() + firstDay && !(i % 7 === 0)) {
                calender.push([])
                calender[calender.length - 1].push(...date);
            }
        }
        this.setState({
            firstDay: firstDay,
            calender: calender,
        })
    }

    render() {
        let calender = this.state.calender;
        // console.log(this.state);
        return (
            <div className="calender">
                <Month
                    monthIndex={this.props.monthIndex}
                    year={this.props.year}
                />
                <div className="calenderTable">
                    <table className='table'>
                        <thead className="days thead">
                            <tr className="tr">
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                calender.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                item.map((date, index) => {
                                                    let date_ = `${this.props.monthIndex + 1}-${date}-${this.props.year}`;
                                                    let highLightStyle = {
                                                        backgroundColor: '#6b6bde', 
                                                        color: 'white',
                                                        fontWeight: '900'
                                                    }
                                                    let currentDate = this.state.date.getDate();
                                                    let reachedStartDate = true;
                                                    if(this.props.index === 0) {
                                                        reachedStartDate = date >= currentDate;
                                                    }
                                                    return (
                                                        <td className={(date === 0  || !reachedStartDate) ? "" : "date"} key={index} id={date_} onClick={(date === 0 || !reachedStartDate)? null : this.pickDates} 
                                                            style={!reachedStartDate ? {backgroundColor: 'rgb(245, 242, 242)'} : {}}
                                                        >
                                                            <h4 className="content" id={date_} style={this.state.dates.includes(date_) ? highLightStyle : {}}>
                                                                {(date === 0) ? '' : date}
                                                            </h4>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
