import React, { Component } from 'react'
import Calender from '../layout/Calender'
import RsvpList from '../layout/RsvpList';
import axios from 'axios';

export default class ShowCalender extends Component {
    state = {
        date: new Date(),
        calendersFirstDays: [],
        rsvp: [],
        rsvpListOpen: false,
        username: 'cpGridKonnect12',
        password: 'L1feStyle2WEalth#5161',
        userDataSet: false,
        orgDataSet: false,
        rsvpDataSet: false,
    }

    getFirstDay = () => {
        const DATE = this.state.date;

        let startDay = DATE.getDay();
        let currentDate = DATE.getDate();

        for (var i = 0; i < (currentDate - 1); i++) {
            startDay--;
            if (startDay === 0 && i < (currentDate - 2)) {
                startDay = 7;
            }
        }
        // console.log(startDay)
        return startDay;
    }

    getFirstEndDate = (month) => {
        let year = this.state.date.getUTCFullYear()
        let endDay = 30;
        if (month === 1) {
            endDay = (year % 4 === 0) ? 29 : 28;
            return endDay;
        } else if (month === 3 || month === 5 || month === 8 || month === 10) {
            endDay = 30;
            return endDay;
        } else {
            endDay = 31;
            return endDay;
        }
    }

    openRsvpList = () => {
        this.setState({
            rsvpListOpen: true
        })
    }

    closeRsvpList = () => {
        this.setState({
            rsvpListOpen: false
        })
    }


    getEndDay = (firstDay, month) => {
        let lastDay = firstDay;
        for(let i = 1; i < this.getFirstEndDate(month); i++){
            lastDay = (lastDay + 1 === 7) ? 0 : lastDay + 1;
        }
        return lastDay;        
    }

    getRsvpData = (index, data) => {
        let calenderRsvp =  this.state.calenderRsvp;
        calenderRsvp[index] = data;
        this.setState({
            calenderRsvp
        })
    }

    componentDidMount() {
        let newMonth = this.state.date.getUTCMonth();
        let newFirstDay = this.getEndDay(this.getFirstDay(), newMonth) + 1;
        let calendersFirstDays = [{firstDay: this.getFirstDay(), month:newMonth}];
        let calenderRsvp = [[]];
        for(let i = 0; i < 6; i++) {
            newMonth = newMonth + 1 === 12 ? 0 : newMonth + 1;
            calendersFirstDays.push({firstDay:newFirstDay, month:newMonth})
            newFirstDay = (this.getEndDay(newFirstDay, newMonth) + 1 === 7) ? 0 : this.getEndDay(newFirstDay, newMonth) + 1;
            calenderRsvp.push([]);
        }
        this.setState({
            calendersFirstDays: calendersFirstDays,
            calenderRsvp
        })
    }

    render() {
        let calendersFirstDays = this.state.calendersFirstDays;
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // console.log(this.state.userExistingRsvpList);
        return (
            <div className='screenContainer'>
                <RsvpList
                    rsvpDates={this.state.calenderRsvp}
                    open={this.state.rsvpListOpen}
                    closeRsvpList={this.closeRsvpList}
                />

                <div className={`screen datePicker${this.state.rsvpListOpen ? ' blur' : ''}`}>
                    {
                        calendersFirstDays.map((item, index) => {
                            return (
                                <Calender
                                    firstDay={item.firstDay}
                                    monthIndex={item.month}
                                    month={MONTHS[item.month]}
                                    year={2020}
                                    index={index}
                                    key={index}
                                    getRsvpData={this.getRsvpData}
                                />
                            )
                        })
                    }
                </div>
                <span className="addRsvp" onClick={this.openRsvpList}>
                    <h4>RSVP</h4>
                </span>
                <div className="bottomBox">Header</div>
            </div>
        )
    }
}
