import React from 'react'

export default function UserChildren(props) {
    const [selected, setSelected] = React.useState(false);
    let toggleIncludeChild = (e) => {
        setSelected(!selected);
        props.addFamily(props.item.childUserId);
    }
    return (
        <div>
            <li key={props.index}>
                <h4>{props.item.childFirstName} {props.item.childLastName}</h4>
                <span className="addMember children" id={props.item.childUserId} onClick={toggleIncludeChild} style={selected ? {backgroundColor: 'green', border: '1px solid green'} : {}}>
                    {selected ? <i className='fas fa-check'></i> : null}
                </span>
            </li>
        </div>
    )
}
