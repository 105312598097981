import React from 'react'

export default function Header(props) {
    let hasSalutation = (props.salutation !== '') && (!props.salutation === 0);
    return (
        <nav>
            {/* <button className="navButton hamburger">
                <div className="patty"></div>
                <div className="patty"></div>
                <div className="patty"></div>
            </button> */}

            <div className="churchInfo">
                <div className="logo">
                    <img src={props.logo} alt=""/>
                </div>

                <div className="orgName">
                    <h3>{props.orgShortName}</h3>
                </div>
            </div>

            <div className="welcome">
                <h4>Welcome Back <span>{hasSalutation ? props.salutation: ''}</span> <span className="name">{props.firstName}</span></h4>
                <div className="profilePicture" style={{backgroundImage: `url(${props.profilePicture})`}}>
                </div>
            </div>
        </nav>
    )
}
